import React from 'react';
import { gql, useQuery } from '@apollo/client';
import PageTemplate, { PageTemplateQuery, Page } from '../templates/page';
import patchLiveDataImages from '../utils/patchLiveDataImages';
import PreviewBanner from '../components/PreviewBanner';

export const query = gql`
  query PageTemplateQuery($key: ID!) {
    navigation {
      id
      main {
        id
        page {
          id
          path
          children {
            id
            title
            path
            parent_order
            children {
              id
              title
              path
              parent_order
            }
          }
        }
      }
      blog_page {
        id
        path
      }
    }
    pages(where: { preview_key: $key }) {
      id
      path
      title
      description
      large_top_image
      show_sibling_and_child_links
      image {
        url
      }
      content {
        dynamicComponentType: __typename
        ... on ComponentContentAccordion {
          title
          items {
            content
            id
            title
            faq {
              id
              question
              answer
            }
          }
        }
        ... on ComponentContentEmployee {
          employee {
            id
            name
            position
            phone
            email
            avatar {
              url
            }
          }
        }
        ... on ComponentContentEmployeeCategory {
          id
          show_category_title
          category {
            id
            title
            employees {
              id
              name
              position
              phone
              email
              avatar {
                url
              }
            }
          }
        }
        ... on ComponentContentImage {
          caption
          image {
            url
          }
        }
        ... on ComponentContentText {
          text
        }
      }
      children {
        id
        description
        path
        title
        parent_order
        image {
          url
        }
      }
    }
  }
`;

type PagePreviewTemplateQuery = Omit<PageTemplateQuery['strapi'], 'page'> & { pages: Page[] };

const PreviewPage: React.FC<{ location: Location }> = ({ location }) => {
  const { loading, data } = useQuery<PagePreviewTemplateQuery>(query, {
    variables: { key: location.search.replace('?key=', '') },
  });

  const page = data && data.pages.length > 0 && patchLiveDataImages(data.pages[0]);
  return !loading && data && page ? (
    <>
      <PreviewBanner url={location.origin + page.path} />
      <PageTemplate
        data={{ strapi: { ...data, page, posts: [] } }}
        pathContext={{ numberOfPages: 0, currentPage: 0, showPosts: false }}
      />
    </>
  ) : null;
};

export default PreviewPage;
